<template>
  <h1>{{ type }} Settled History</h1>
</template>

<script>
export default {
  name: 'SettledHistory',
  data() {
    return {
      type: ''
    }
  },
  created() {
    this.type = this.$route.meta.expenses ? 'Expenses' : 'Income'
  }
}
</script>
